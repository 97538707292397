.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}
.booking-container {
  height: calc(100vh - 100px);
  display: flex;
  flex-wrap: wrap;
}
.booking-item-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  cursor: pointer;
  position: relative;
  background-size: cover;
  text-decoration: none;
}
.booking-item-container:hover > .booking-item-overlay {
  opacity: 1;
}
.booking-item-container:hover > .booking-item-header-text {
  color: black;
  background-color: white;
}
.booking-item-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  transition: opacity 200ms ease-in;
}
.booking-item-header-text {
  font-size: 2.5vw;
  color: white;
  font-family: 'montserrat';
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: black;
  padding: 10px;
  z-index: 2;
  transition: all 200ms ease-in;
}
@media only screen and (max-width: 1110px) {
  .booking-item-container {
    width: 100%;
  }
  .booking-item-header-text {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 980px) {
  .booking-container {
    height: calc(100vh - 70px);
  }
}
