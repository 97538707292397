.admin-header {
  background-color: white;
  height: 70px;
  width: 100%;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
}
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 25px;
  top: 35px;
}

.bm-burger-bars {
  background: #252330;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: white;
}

.bm-menu {
  background: white;
  padding: 6vh 1.5em 0;
  overflow-y: hidden;
  top: 0;
  height: 100% !important;
  overflow: hidden !important;
  text-align: center;
  font-size: 7vw;
}
.bm-menu-wrap {
  position: fixed;
  top: 0;
  width: 100% !important;
}

.bm-morph-shape {
  fill: white;
}

.bm-item-list {
  color: black;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
  margin-top: 30px;
  width: 100%;
  font-weight: 700;
  font-size: 1.4em;
  color: #252330;
  text-decoration: none;
  outline: none !important;
}
.bm-item span {
  margin-left: 10px;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: black;
  text-decoration: none;
}

.logo-mobile {
  position: relative;
  height: 50%;
  margin-left: auto;
  margin-right: auto;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
}
@media (orientation: landscape) {
  .bm-menu {
    font-size: 3vw;
    padding: 0em 1.5em 0;
  }
  .bm-item {
    margin-top: 25px;
  }
}
