.awards-page-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.awards-cell {
  width: 20vw;
  margin: 5vw;
  transition: transform 200ms ease;
}
.awards-cell:hover {
  transform: scale(1.05);
}
.awards-cell-image {
  width: 100%;
}
.awards-cell-header {
  font-size: 3em;
  font-family: Montserrat;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
}
.awards-cell-description {
  font-size: 1.6em;
  text-align: center;
}
.react-images__blanket {
  z-index: 1200 !important;
}
.react-images__positioner {
  z-index: 1200 !important;
}

@media only screen and (max-width: 1600px) {
  .awards-cell-header {
    font-size: 1.5em;
  }
  .awards-cell-description {
    font-size: 1em;
  }
}
@media only screen and (max-width: 1224px) {
  .awards-cell {
    width: 60vw;
    margin: 5vw;
  }
}
