@import url(https://use.typekit.net/nlf7rfv.css);
@import url(https://use.typekit.net/tgc7tni.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  max-height: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #a9a9a9;
}
.navbar-item:hover .dropdown-extension {
  display: block;
}
.navbar-item:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
}
.navbar-collection-left {
  width: 40%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  text-align: right;
}
.navbar-collection-right {
  width: 40%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  text-align: right;
}
.navbar-logo-item {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.navbar-logo {
  height: 50%;
  position: relative;
}
.navbar-item {
  margin: 20px;
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.navbar-text {
  font-size: 16px;
  letter-spacing: 0.15em;
  font-family: 'Montserrat', sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #a9a9a9,
    #a9a9a9 50%,
    #333333 50%
  );
  background-size: 200% 100%;
  background-position: 100%;
}
.navbar-item:hover .navbar-text {
  transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
}
.headroom {
  z-index: 2 !important;
}
@media screen and (max-width: 1290px) {
  .navbar-text {
    font-size: 12px;
  }
}
@media screen and (max-width: 1050px) {
  .navbar-text {
    font-size: 10px;
  }
}
@media screen and (min-width: 2000px) {
  .navbar {
    max-height: 150px;
    height: 150px;
  }
  .navbar-text {
    font-size: 20px;
  }
  .navbar-item {
    margin: 50px;
  }
}
@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  .navbar {
    max-width: 100vw;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
  }
  .navbar-text {
    background-image: none;
  }
  .navbar-logo-item {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    top: 0;
  }
  .navbar-collection-left {
    margin-left: 10vw;
    margin-right: 0;
  }
  .navbar-collection-right {
    margin-right: 10vw;
    margin-left: 0;
  }
  .navbar-logo {
    height: 50%;
    margin: 0;
    padding: 0;
  }
}

.awards-page-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.awards-cell {
  width: 20vw;
  margin: 5vw;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}
.awards-cell:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.awards-cell-image {
  width: 100%;
}
.awards-cell-header {
  font-size: 3em;
  font-family: Montserrat;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
}
.awards-cell-description {
  font-size: 1.6em;
  text-align: center;
}
.react-images__blanket {
  z-index: 1200 !important;
}
.react-images__positioner {
  z-index: 1200 !important;
}

@media only screen and (max-width: 1600px) {
  .awards-cell-header {
    font-size: 1.5em;
  }
  .awards-cell-description {
    font-size: 1em;
  }
}
@media only screen and (max-width: 1224px) {
  .awards-cell {
    width: 60vw;
    margin: 5vw;
  }
}

.footer-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: black;
  margin: 0;
  font-size: 2.2vw;
  margin-bottom: 20px;
  margin-top: 5vh;
  text-align: left;
}
.footer-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: black;
  margin: 0;
  font-size: 1.5vw;
  margin-bottom: 10px;
  margin-top: 3vh;
  text-align: left;
}
.footer-text {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-weight: 300;
  margin: 0;
  font-size: 1.4vw;
  text-align: left;
}
.footer-copyright-text {
  font-family: 'Poppins', sans-serif;
  color: black;
  font-weight: 400;
  margin: 0;
  font-size: 20px;
  text-align: center;
  padding-top: 1vh;
  margin-top: 0;
}
.footer-copyright-link {
  text-decoration: none;
  color: black;
  font-weight: 700;
}
.footer-item-container {
  display: inline-block;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
}
.footer-item-container > a {
  text-decoration: none;
}
.footer-icon-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 4vh;
}
.footer-divider {
  height: 1px;
  background-color: black;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1200px) {
  .footer-title {
    font-size: 3.3vw;
  }
  .footer-subtitle {
    font-size: 2.5vw;
    margin-top: 3vh;
  }
  .footer-text {
    font-size: 2vw;
  }
}
@media screen and (max-width: 700px) {
  .footer-title {
    font-size: 5vw;
  }
  .footer-subtitle {
    font-size: 4.5vw;
    margin-top: 3vh;
  }
  .footer-text {
    font-size: 4vw;
  }
  .footer-item-container {
    width: 70vw;
    display: block;
    text-align: left;
  }
}

.admin-header {
  background-color: white;
  height: 70px;
  width: 100%;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 25px;
  top: 35px;
}

.bm-burger-bars {
  background: #252330;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: white;
}

.bm-menu {
  background: white;
  padding: 6vh 1.5em 0;
  overflow-y: hidden;
  top: 0;
  height: 100% !important;
  overflow: hidden !important;
  text-align: center;
  font-size: 7vw;
}
.bm-menu-wrap {
  position: fixed;
  top: 0;
  width: 100% !important;
}

.bm-morph-shape {
  fill: white;
}

.bm-item-list {
  color: black;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
  margin-top: 30px;
  width: 100%;
  font-weight: 700;
  font-size: 1.4em;
  color: #252330;
  text-decoration: none;
  outline: none !important;
}
.bm-item span {
  margin-left: 10px;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: black;
  text-decoration: none;
}

.logo-mobile {
  position: relative;
  height: 50%;
  margin-left: auto;
  margin-right: auto;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
}
@media (orientation: landscape) {
  .bm-menu {
    font-size: 3vw;
    padding: 0em 1.5em 0;
  }
  .bm-item {
    margin-top: 25px;
  }
}

.media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  left: 0px;
  position: relative;
  z-index: -2;
  overflow: hidden;
}
.about-divider {
  width: 50vw;
  border-style: solid;
  border-color: black;
  border-width: 0 2px 0 0;
  height: 15vw;
  margin-top: 5vh;
}
.collection-info-text {
  font-family: 'Montserrat';
  font-size: 16px;
}
.media-container {
  height: 90vh;
  overflow: hidden;
}
.video-overlay {
  position: absolute;
  height: inherit;
  width: 100%;
  top: 100px;
  transition: background 0.5s linear;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.booking-button-container {
  padding: 30px 60px;
  font-size: 40px;
  textdecoration: none;
  color: white;
  border-style: solid;
  border-color: white;
  font-weight: 700;
  letter-spacing: 5px;
  font-family: 'Montserrat', Arial;
  background: rgba(15, 15, 15, 0.3);
  transition: 0.7s;
  text-decoration: none;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  top: 50vh;
  z-index: 0;
  transition: opacity 0.5s linear, background 0.5s ease;
  cursor: pointer;
}
.booking-button-container:hover {
  background: rgba(255, 255, 255, 0.2);
}
.instagram-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.instagram-image-slider {
  width: 100vw;
  height: 100vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}
.instagram-container {
  background-color: #ffffff;
}
.instagram-feed {
  display: grid;

  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 25vw);
  grid-gap: 0;
  padding-bottom: 10vw;
}
.instagram-profile-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 2vw;
  margin-bottom: 2vw;
}
.instagram-profile-picture {
  border-radius: 50%;
  height: 7vw;
  margin-left: 10vw;
}
.instagram-profile-username {
  font-family: 'Montserrat';
  text-transform: uppercase;
  margin-left: 2vw;
  font-size: 2.3vw;
}
.instagram-profile-link {
  text-decoration: none;
  color: black;
}

.readmore-container {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #ffffff;
  height: 45vw;
}
.readmore-header-container {
  width: 40%;
  position: relative;
  display: block;
  left: 23vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vw;
  padding: 0;
}
.readmore-image {
  display: block;
  height: 55vw;
  position: absolute;
  margin-left: 10vw;
}
.readmore-header-text {
  font-size: 1.5vw;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 2vw;
}
.readmore-awards-text {
  font-size: 1.1vw;
  font-family: 'poppins', sans-serif;
  font-weight: 300;
  font-style: italic;
  letter-spacing: 2px;
  margin-bottom: 3.5vw;
  color: black;
}
.readmore-awards-header-text {
  background-color: black;
  color: white;
  margin-bottom: 20px;
  padding: 5px;
}
.readmore-link-text {
  text-decoration: none;
  cursor: pointer;
  font-size: 1.7vw;
  letter-spacing: 0.15em;
  font-weight: 400;
  font-family: 'Montserrat';
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #a9a9a9,
    #a9a9a9 50%,
    #333333 50%
  );
  background-size: 200% 100%;
  background-position: 100%;
}
.readmore-link-text:hover {
  transition: all 0.7s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
}
.collection-container {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f2f2f2;
  height: 60vw;
}
.collection-first-image {
  display: block;
  height: 33vw;
  position: absolute;
  margin-left: 20vw;
  margin-top: 15.2vw;
}
.collection-second-image {
  display: block;
  height: 40vw;
  position: absolute;
  margin-left: 36vw;
  margin-top: 12vw;
  z-index: 1;
}
.collection-third-image {
  display: block;
  height: 25vw;
  position: absolute;
  margin-left: 62vw;
  margin-top: 25vw;
}
.collection-header-container {
  text-align: left;
  width: 27%;
  font-size: 1.2vw;
  position: absolute;
  display: block;
  left: 67vw;
  margin-top: 10vw;
  padding: 0;
  color: black;
  font-family: 'Poppins', sans-serif;
  z-index: 1;
}
.collection-header-text {
  font-size: 2.4vw;
  font-weight: 900;
  margin-bottom: 0;
  font-family: 'Montserrat';
}
.collection-header-container > p {
  font-family: 'Montserrat';
  font-size: 1.3vw;
  font-weight: 400;
}
.collection-link-container {
  width: 30%;
  position: absolute;
  display: block;
  left: 12vw;
  margin-top: 49.5vw;
  padding: 0;
  text-align: center;
}
.collection-link-text {
  text-decoration: none;
  cursor: pointer;
  font-size: 1.2vw;
  font-weight: 100;
  font-family: 'Montserrat';
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #a9a9a9,
    #a9a9a9 50%,
    #333333 50%
  );
  background-size: 200% 100%;
  background-position: 100%;
}
.collection-link-text:hover {
  transition: all 0.7s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
}
.collection-image {
  display: block;
  width: 100%;
  height: 50vh;
  object-fit: cover;
  opacity: 0.6;
  position: absolute;
}
.collection-link-text-mobile {
  font-size: 5vw;
  color: black;
  text-decoration: none;
}
.readmore-link-text-mobile {
  font-size: 1.4rem;
  margin-top: 70px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: black;
  color: white;
  font-family: 'Montserrat';
  text-decoration: none;
}

@media screen and (max-width: 980px) {
  .booking-button-container {
    font-size: 25px;
    padding: 20px 20px;
    width: 55vw;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .instagram-profile-container {
    -webkit-justify-content: initial;
        -ms-flex-pack: initial;
            justify-content: initial;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 3vw;
    margin-bottom: 3vw;
  }
  .instagram-profile-picture {
    height: 10vw;
    margin-left: 5vw;
  }
  .instagram-profile-username {
    margin-left: 2vw;
    font-size: 20px;
  }
  .instagram-container {
    padding-bottom: 10vw;
  }
  .collection-container {
    padding-top: 0;
    background-color: white;
    text-align: center;
    height: 50vh;
    padding-bottom: 0;
  }
  .collection-header-container {
    position: absolute;
    height: 40vh;
    display: block;
    text-align: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    left: 0;
    padding: 0;
    margin-top: 14vw;
  }
  .collection-header-text {
    font-size: 6em;
    font-family: 'Montserrat', sans-serif;
    background-color: black;
    font-style: italic;
    color: white;
    padding: 10px;
    text-transform: uppercase;
    margin-top: 0;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }

  .readmore-container {
    padding-top: 100px;
    background-color: #ffffff;
    height: auto;
    padding-bottom: 100px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-decoration: none;
  }
  .readmore-header-container {
    position: static;
    position: initial;
    margin-top: 40px;
    margin-bottom: 40px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    width: 90vw;
    padding: 0;
  }

  .readmore-image {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    opacity: 0.5;
    position: absolute;
    margin: 0;
  }
  .readmore-header-text {
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 2px;
    margin-top: 0;
    margin-bottom: 50px;
    color: black;
  }

  .readmore-awards-text {
    font-size: 1rem;
    font-weight: 700;
  }
}

@media screen and (max-width: 980px) and (orientation: landscape) {
  .media {
    object-position: 50% 50%;
  }
  .readmore-header-container {
    height: 50vh;
  }
  .collection-header-container {
    height: 60vh;
  }
  .instagram-image-slider {
    width: 100vh;
    height: 100vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 2000px) {
  .video-overlay {
    top: 150px;
  }
}

@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  .instagram-feed {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .feed-cell {
    width: 25vw;
    height: 25vw;
    -ms-grid-column: 3;
  }
  .readmore-link-text {
    background-image: none;
    color: black;
  }
  .collection-link-text {
    background-image: none;
    color: black;
  }
}

@supports (object-fit: cover) and (-ms-ime-align: auto) {
  .media {
    height: auto;
  }
  .instagram-feed {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .feed-cell {
    width: 25vw;
    height: 25vw;
    -ms-grid-column: 3;
  }
}

.profiles-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2vh;
}
.profile-overlay-content {
  position: absolute;
  bottom: 5%;
  width: 100%;
}
.profile-container {
  width: 22vw;
  height: 22vw;
  margin-top: 4vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.profile-image {
  width: 70%;
  height: 70%;
  object-fit: cover;
  border-radius: 50%;
  transition: border-radius 200ms linear, height 200ms linear,
    width 200ms linear, opacity 200ms linear;
}

.profile-overlay {
  position: absolute;
  height: calc(22vw * 0.7);
  width: calc(22vw * 0.7);
  border-radius: 50%;
  transition: border-radius 200ms linear, background-color 200ms linear,
    height 200ms linear;
}
.profile-overlay:hover,
.profile-overlay:active,
.profile-overlay:focus {
  background: rgba(0, 0, 0, 0.2);
  height: 22vw;
  border-radius: 0;
}

.profile-read-more {
  position: absolute;
  margin: 0;
  padding-top: 0.3vw;
  text-align: center;
  width: 100%;
  font-family: 'Montserrat', Arial;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: opacity 200ms linear;
  font-size: 1vw;
  visibility: visible;
  font-weight: 400;
}

.profile-overlay:hover ~ .profile-image {
  border-radius: 0;
  height: 100%;
  width: 70%;
}

.profile-overlay:hover .profile-overlay-name {
  visibility: visible;
  opacity: 1;
}
.profile-overlay:hover .profile-overlay-title {
  visibility: visible;
  opacity: 1;
}
.profile-overlay:hover .profile-overlay-award {
  visibility: visible;
  opacity: 1;
}
.profile-overlay:hover .profile-name {
  opacity: 0;
}
.profile-overlay:hover .profile-read-more {
  opacity: 0;
}
.profile-overlay-name-header {
  margin-left: 3%;
  margin-bottom: 3%;
  margin-right: 3%;

  font-size: 0;
}
.profile-overlay-name {
  background-color: white;
  font-family: 'Montserrat', Arial;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2vw;
  opacity: 0;
  transition: opacity 200ms linear;
  visibility: hidden;
  letter-spacing: 2px;
  text-indent: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding-left: 2px;
  padding-right: 2px;
}
.profile-overlay-title-header {
  margin-bottom: 1px;
  margin-top: 1px;
  font-size: 0;
  margin-left: 3%;
  margin-right: 3%;
}
.profile-overlay-award-header {
  margin-bottom: 1px;
  margin-top: 1px;
  font-size: 0;
  margin-left: 3%;
  margin-right: 3%;
}
.profile-overlay-title {
  background-color: white;
  font-family: 'Montserrat', Arial;
  text-transform: uppercase;
  text-align: left;
  font-weight: 700;
  font-size: 0.8vw;
  opacity: 0;
  transition: opacity 200ms linear;
  visibility: hidden;
  letter-spacing: 0.5px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding-left: 2px;
  padding-right: 2px;
}
.profile-divider {
  height: 5px;
}
.profile-overlay-award {
  background-color: white;
  font-family: 'Montserrat', Arial;
  text-transform: uppercase;
  text-align: left;
  font-weight: 700;
  font-size: 0.6vw;
  opacity: 0;
  transition: opacity 200ms linear;
  visibility: hidden;
  letter-spacing: 0.5px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding-left: 2px;
  padding-right: 2px;
}
.profile-name {
  position: absolute;
  margin: 0;
  padding-bottom: 10vw;
  padding-top: 1vw;
  text-align: center;
  width: 100%;
  font-family: 'Montserrat', Arial;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: opacity 200ms linear;
  font-size: 1.3vw;
  visibility: visible;
}

.about-header {
  font-size: 4vw;
  text-align: center;
  font-family: 'Montserrat', Arial;
  letter-spacing: 0.7vw;
  font-weight: 700;
  color: black;
  margin-top: 10vh;
}
.decorated {
  overflow: hidden;
  text-align: center;
}
.decorated > span {
  position: relative;
  display: inline-block;
  font-size: 3vw;
  font-family: 'Montserrat', Arial;
  color: #212121;
  letter-spacing: 3px;
  font-weight: 300;
}
.decorated > span:before,
.decorated > span:after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 2px solid;
  border-color: #a9a9a9;
  width: 5vw; /* half of limiter */
  margin: 0 20px;
}
.decorated > span:before {
  right: 100%;
}
.decorated > span:after {
  left: 100%;
}
.megastore {
  margin-top: 5vh;
}
.premium {
  margin-top: 5vh;
}
.about-divider {
  width: 50vw;
  border-style: solid;
  border-width: 0 2px 0 0;
  height: 15vw;
  margin-top: 5vh;
}
.about-quote {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2.5vw;
  letter-spacing: 0.4vw;
  font-style: italic;
  text-align: center;
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
}
.about-info-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5vw;
  text-align: left;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vw;
}
.about-greeting-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8vw;
  text-align: center;
  width: 45vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vw;
}

@media only screen and (max-width: 980px) {
  .profile-container {
    width: 45vw;
    height: 45vw;
  }

  .decorated > span {
    font-size: 20px;
  }

  .profile-overlay {
    height: calc(45vw * 0.7);
    width: calc(45vw * 0.7);
  }
  .profile-overlay:hover,
  .profile-overlay:active,
  .profile-overlay:focus {
    height: 45vw;
  }
  .profile-name {
    margin-top: 3vw;
    font-size: 2.5vw;
  }

  .profile-read-more {
    font-size: 1.8vw;
  }

  .profile-overlay-name {
    font-size: 2.7vw;
  }
  .profile-overlay-title {
    font-size: 2vw;
  }
  .profile-overlay-award {
    font-size: 1.5vw;
  }
  .about-quote {
    width: 90vw;
    font-size: 4.5vw;
  }
  .about-info-text {
    font-size: 3.5vw;
    width: 80vw;
  }
  .about-greeting-text {
    font-size: 6vw;
    width: 80vw;
  }
}
@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  .profile-overlay {
    position: absolute;
    margin-top: -11vw;
    visibility: visible;
    height: 22vw;
    width: calc(22vw * 0.7);
    transition: border-radius 200ms linear, background-color 200ms linear,
      height 200ms linear;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }
  .profile-image {
    width: 70%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
    transition: border-radius 200ms linear, height 200ms linear,
      width 200ms linear, opacity 200ms linear;
  }
  .profile-name {
    margin-top: 10px;
  }
  .profile-container {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 0\0) and (min-resolution: +72dpi) and (max-width: 980px) {
  .profile-overlay {
    height: 45vw;
    width: calc(45vw * 0.7);
    margin-top: -22.5vw;
  }
  .profile-name {
    margin-top: 2.5vw;
  }
}

@supports (-ms-ime-align: auto) {
  .profile-name {
    margin-top: 0.5vw;
  }
  @media only screen and (max-width: 970px) {
    .profile-name {
      margin-top: 2vw;
    }
  }
}

.about-employee-profile-left-container {
  height: 100vh;
}
.about-employee-profile-left-picture {
  display: block;
  margin-top: -2vw;
  height: 90vh;
  width: 35vw;
  position: absolute;
  margin-left: 8vw;
  object-fit: cover;
  object-position: 0 10%;
  z-index: -1;
}
.about-employee-profile-left-text-container {
  margin-left: 25vw;
  text-align: center;
}
.about-employee-profile-left-name-text {
  font-size: 5vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  font-family: 'Muli', Arial;
  font-weight: 800;
  padding-top: 7vw;
  margin-bottom: 0;
}
.about-employee-profile-left-title-text {
  font-size: 2.3vw;
  font-family: 'Muli', Arial;
}
.about-employee-profile-left-award-text {
  font-size: 1.7vw;
  font-family: 'Muli', Arial;
  margin: 0;
  padding: 0;
}

.about-employee-profile-right-container {
  height: 100vh;
}
.about-employee-profile-right-picture {
  display: block;
  margin-top: -2vw;
  height: 90vh;
  width: 35vw;
  position: absolute;
  margin-left: 57vw;
  object-fit: cover;
  object-position: 0 10%;
  z-index: -1;
}
.about-employee-profile-right-text-container {
  margin-right: 25vw;
  text-align: center;
}
.about-employee-profile-right-name-text {
  font-size: 5vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  font-family: 'Muli', Arial;
  font-weight: 800;
  padding-top: 7vw;
  margin-bottom: 0;
}
.about-employee-profile-right-title-text {
  font-size: 2.3vw;
  font-family: 'Muli', Arial;
}
.about-employee-profile-right-award-text {
  font-size: 1.7vw;
  font-family: 'Muli', Arial;
  margin: 0;
  padding: 0;
}
.about-employee-profile-name-text {
  text-align: center;
  font-size: 11vw;
  font-weight: 800;
  font-family: 'Muli', Arial;
  text-transform: uppercase;
  margin-bottom: 0;
  color: white;
}
.about-employee-profile-title-text {
  text-align: center;
  font-size: 4vw;
  font-family: 'Muli', Arial;
  color: white;
  margin-top: 0;
}
.about-employee-profile-award-text {
  margin: 0;
  text-align: center;
  font-size: 4.5vw;
  font-family: 'Muli', Arial;
  color: white;
}

.react-images__blanket {
  z-index: 1200 !important;
}
.react-images__positioner {
  z-index: 1200 !important;
}
.ril-toolbar {
  background-color: transparent !important;
}
.react-images__footer {
  opacity: 1 !important;
}

.react-images__header {
  opacity: 1 !important;
}
.carousel-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2000;
}
.carousel-item {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.carousel-image {
  height: 80%;
  max-width: 100%;
  object-fit: cover;
}
.carousel-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 20px;
  height: 5vh;
  width: 5vh;
  font-size: 70px;
  color: white;
  font-family: montserrat;
  text-align: center;
  z-index: 2000;
}
.collection-left-container {
  height: calc(100vh - 100px);
  transition: background-color 200ms linear;
  cursor: pointer;
}

.collection-left-container:hover > .collection-left-picture {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}

.collection-left-picture {
  display: block;
  margin-top: 4vh;
  height: 80vh;
  width: 30vw;
  position: absolute;
  margin-left: 10vw;
  object-fit: cover;
  object-position: center;
  transition: opacity 1s linear, -webkit-transform 1s ease;
  transition: opacity 1s linear, transform 1s ease;
  transition: opacity 1s linear, transform 1s ease, -webkit-transform 1s ease;
}
.collection-left-text-container {
  margin-left: 40vw;
  text-align: center;
  position: relative;
  z-index: 1;
}
.collection-left-title-text {
  font-size: 5vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  font-family: 'Muli', Arial;
  font-weight: 800;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 30vh;
}
.collection-left-description-text {
  margin-top: 2vh;
  font-size: 1.5vw;
  font-family: 'Muli', Arial;
}

.collection-right-container {
  height: calc(100vh - 100px);
  transition: background-color 200ms linear;
  cursor: pointer;
}

.collection-right-container:hover > .collection-right-picture {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}

.collection-right-picture {
  display: block;
  margin-top: 4vh;
  height: 80vh;
  width: 30vw;
  position: absolute;
  margin-left: 55vw;
  object-fit: cover;
  object-position: center;
  transition: opacity 1s linear, -webkit-transform 1s ease;
  transition: opacity 1s linear, transform 1s ease;
  transition: opacity 1s linear, transform 1s ease, -webkit-transform 1s ease;
}
.collection-right-text-container {
  margin-right: 40vw;
  text-align: center;
  position: relative;
  z-index: 1;
}
.collection-right-title-text {
  font-size: 5vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  font-family: 'Muli', Arial;
  font-weight: 800;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 25vh;
}
.collection-right-description-text {
  margin-top: 2vh;
  font-size: 1.5vw;
  font-family: 'Montserrat', Arial;
}
@media screen and (min-width: 2000px) {
  .collection-left-container {
    height: calc(100vh - 150px);
  }

  .collection-right-container {
    height: calc(100vh - 150px);
  }
}
@media screen and (max-width: 980px) {
  .collection-right-container {
    padding-top: 0;
    height: 90vh;
  }
  .collection-left-container {
    padding-top: 0;
    height: 90vh;
  }
  .collection-left-picture {
    margin-top: 10vh;
    height: 55vh;
    width: 80vw;
    margin-left: 10vw;
  }
  .collection-right-picture {
    margin-top: 10vh;
    height: 55vh;
    width: 80vw;
    margin-left: 10vw;
  }
  .collection-right-text-container {
    margin-right: 0;
  }
  .collection-left-text-container {
    margin-left: 0;
  }
  .collection-right-title-text {
    font-size: 10vw;
    text-transform: uppercase;
    letter-spacing: 1vw;
    font-family: 'Muli', Arial;
    font-weight: 800;
    margin-top: 0;
    padding-top: 70vh;
    text-align: center;
    margin-bottom: 0;
  }
  .collection-left-title-text {
    font-size: 10vw;
    text-transform: uppercase;
    letter-spacing: 1vw;
    font-family: 'Muli', Arial;
    font-weight: 800;
    margin-top: 0;
    padding-top: 70vh;
    text-align: center;
    margin-bottom: 0;
  }
  .collection-right-description-text {
    margin-top: 1vh;
    font-size: 4vw;
    font-family: 'Montserrat', Arial;
  }
  .collection-left-description-text {
    margin-top: 1vh;
    font-size: 4vw;
    font-family: 'Montserrat', Arial;
  }
}
@media screen and (max-width: 980px) and (orientation: landscape) {
  .collection-right-container {
    padding-top: 0;
    height: 100vh;
  }
  .collection-left-container {
    padding-top: 0;
    height: 100vh;
  }
  .collection-left-picture {
    margin-top: 0;
    height: 100vh;
    width: 100vw;
    margin-left: 0;
    object-position: center;
  }
  .collection-right-picture {
    margin-top: 0;
    height: 100vh;
    width: 100vw;
    margin-left: 0;
    object-position: center;
  }
  .collection-right-text-container {
    margin-right: 0;
  }
  .collection-left-text-container {
    margin-left: 0;
  }
  .collection-right-title-text {
    font-size: 5vw;
    text-transform: uppercase;
    letter-spacing: 1vw;
    font-family: 'Muli', Arial;
    font-weight: 800;
    margin-top: 0;
    padding-top: 50vh;
    text-align: center;
    margin-bottom: 0;
  }
  .collection-left-title-text {
    font-size: 5vw;
    text-transform: uppercase;
    letter-spacing: 1vw;
    font-family: 'Muli', Arial;
    font-weight: 800;
    margin-top: 0;
    padding-top: 50vh;
    text-align: center;
    margin-bottom: 0;
  }
  .collection-right-description-text {
    margin-top: 1vh;
    font-size: 4vw;
    font-family: 'Muli', Arial;
    visibility: hidden;
  }
  .collection-left-description-text {
    margin-top: 1vh;
    font-size: 4vw;
    font-family: 'Muli', Arial;
    visibility: hidden;
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}
.booking-container {
  height: calc(100vh - 100px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.booking-item-container {
  width: 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  background-position: center;
  cursor: pointer;
  position: relative;
  background-size: cover;
  text-decoration: none;
}
.booking-item-container:hover > .booking-item-overlay {
  opacity: 1;
}
.booking-item-container:hover > .booking-item-header-text {
  color: black;
  background-color: white;
}
.booking-item-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  transition: opacity 200ms ease-in;
}
.booking-item-header-text {
  font-size: 2.5vw;
  color: white;
  font-family: 'montserrat';
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: black;
  padding: 10px;
  z-index: 2;
  transition: all 200ms ease-in;
}
@media only screen and (max-width: 1110px) {
  .booking-item-container {
    width: 100%;
  }
  .booking-item-header-text {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 980px) {
  .booking-container {
    height: calc(100vh - 70px);
  }
}

