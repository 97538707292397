.react-images__blanket {
  z-index: 1200 !important;
}
.react-images__positioner {
  z-index: 1200 !important;
}
.ril-toolbar {
  background-color: transparent !important;
}
.react-images__footer {
  opacity: 1 !important;
}

.react-images__header {
  opacity: 1 !important;
}
.carousel-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2000;
}
.carousel-item {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-image {
  height: 80%;
  max-width: 100%;
  object-fit: cover;
}
.carousel-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 20px;
  height: 5vh;
  width: 5vh;
  font-size: 70px;
  color: white;
  font-family: montserrat;
  text-align: center;
  z-index: 2000;
}
.collection-left-container {
  height: calc(100vh - 100px);
  transition: background-color 200ms linear;
  cursor: pointer;
}

.collection-left-container:hover > .collection-left-picture {
  transform: scale(1.05);
}

.collection-left-picture {
  display: block;
  margin-top: 4vh;
  height: 80vh;
  width: 30vw;
  position: absolute;
  margin-left: 10vw;
  object-fit: cover;
  object-position: center;
  transition: opacity 1s linear, transform 1s ease;
}
.collection-left-text-container {
  margin-left: 40vw;
  text-align: center;
  position: relative;
  z-index: 1;
}
.collection-left-title-text {
  font-size: 5vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  font-family: 'Muli', Arial;
  font-weight: 800;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 30vh;
}
.collection-left-description-text {
  margin-top: 2vh;
  font-size: 1.5vw;
  font-family: 'Muli', Arial;
}

.collection-right-container {
  height: calc(100vh - 100px);
  transition: background-color 200ms linear;
  cursor: pointer;
}

.collection-right-container:hover > .collection-right-picture {
  transform: scale(1.05);
}

.collection-right-picture {
  display: block;
  margin-top: 4vh;
  height: 80vh;
  width: 30vw;
  position: absolute;
  margin-left: 55vw;
  object-fit: cover;
  object-position: center;
  transition: opacity 1s linear, transform 1s ease;
}
.collection-right-text-container {
  margin-right: 40vw;
  text-align: center;
  position: relative;
  z-index: 1;
}
.collection-right-title-text {
  font-size: 5vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  font-family: 'Muli', Arial;
  font-weight: 800;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 25vh;
}
.collection-right-description-text {
  margin-top: 2vh;
  font-size: 1.5vw;
  font-family: 'Montserrat', Arial;
}
@media screen and (min-width: 2000px) {
  .collection-left-container {
    height: calc(100vh - 150px);
  }

  .collection-right-container {
    height: calc(100vh - 150px);
  }
}
@media screen and (max-width: 980px) {
  .collection-right-container {
    padding-top: 0;
    height: 90vh;
  }
  .collection-left-container {
    padding-top: 0;
    height: 90vh;
  }
  .collection-left-picture {
    margin-top: 10vh;
    height: 55vh;
    width: 80vw;
    margin-left: 10vw;
  }
  .collection-right-picture {
    margin-top: 10vh;
    height: 55vh;
    width: 80vw;
    margin-left: 10vw;
  }
  .collection-right-text-container {
    margin-right: 0;
  }
  .collection-left-text-container {
    margin-left: 0;
  }
  .collection-right-title-text {
    font-size: 10vw;
    text-transform: uppercase;
    letter-spacing: 1vw;
    font-family: 'Muli', Arial;
    font-weight: 800;
    margin-top: 0;
    padding-top: 70vh;
    text-align: center;
    margin-bottom: 0;
  }
  .collection-left-title-text {
    font-size: 10vw;
    text-transform: uppercase;
    letter-spacing: 1vw;
    font-family: 'Muli', Arial;
    font-weight: 800;
    margin-top: 0;
    padding-top: 70vh;
    text-align: center;
    margin-bottom: 0;
  }
  .collection-right-description-text {
    margin-top: 1vh;
    font-size: 4vw;
    font-family: 'Montserrat', Arial;
  }
  .collection-left-description-text {
    margin-top: 1vh;
    font-size: 4vw;
    font-family: 'Montserrat', Arial;
  }
}
@media screen and (max-width: 980px) and (orientation: landscape) {
  .collection-right-container {
    padding-top: 0;
    height: 100vh;
  }
  .collection-left-container {
    padding-top: 0;
    height: 100vh;
  }
  .collection-left-picture {
    margin-top: 0;
    height: 100vh;
    width: 100vw;
    margin-left: 0;
    object-position: center;
  }
  .collection-right-picture {
    margin-top: 0;
    height: 100vh;
    width: 100vw;
    margin-left: 0;
    object-position: center;
  }
  .collection-right-text-container {
    margin-right: 0;
  }
  .collection-left-text-container {
    margin-left: 0;
  }
  .collection-right-title-text {
    font-size: 5vw;
    text-transform: uppercase;
    letter-spacing: 1vw;
    font-family: 'Muli', Arial;
    font-weight: 800;
    margin-top: 0;
    padding-top: 50vh;
    text-align: center;
    margin-bottom: 0;
  }
  .collection-left-title-text {
    font-size: 5vw;
    text-transform: uppercase;
    letter-spacing: 1vw;
    font-family: 'Muli', Arial;
    font-weight: 800;
    margin-top: 0;
    padding-top: 50vh;
    text-align: center;
    margin-bottom: 0;
  }
  .collection-right-description-text {
    margin-top: 1vh;
    font-size: 4vw;
    font-family: 'Muli', Arial;
    visibility: hidden;
  }
  .collection-left-description-text {
    margin-top: 1vh;
    font-size: 4vw;
    font-family: 'Muli', Arial;
    visibility: hidden;
  }
}
