.about-employee-profile-left-container {
  height: 100vh;
}
.about-employee-profile-left-picture {
  display: block;
  margin-top: -2vw;
  height: 90vh;
  width: 35vw;
  position: absolute;
  margin-left: 8vw;
  object-fit: cover;
  object-position: 0 10%;
  z-index: -1;
}
.about-employee-profile-left-text-container {
  margin-left: 25vw;
  text-align: center;
}
.about-employee-profile-left-name-text {
  font-size: 5vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  font-family: 'Muli', Arial;
  font-weight: 800;
  padding-top: 7vw;
  margin-bottom: 0;
}
.about-employee-profile-left-title-text {
  font-size: 2.3vw;
  font-family: 'Muli', Arial;
}
.about-employee-profile-left-award-text {
  font-size: 1.7vw;
  font-family: 'Muli', Arial;
  margin: 0;
  padding: 0;
}

.about-employee-profile-right-container {
  height: 100vh;
}
.about-employee-profile-right-picture {
  display: block;
  margin-top: -2vw;
  height: 90vh;
  width: 35vw;
  position: absolute;
  margin-left: 57vw;
  object-fit: cover;
  object-position: 0 10%;
  z-index: -1;
}
.about-employee-profile-right-text-container {
  margin-right: 25vw;
  text-align: center;
}
.about-employee-profile-right-name-text {
  font-size: 5vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  font-family: 'Muli', Arial;
  font-weight: 800;
  padding-top: 7vw;
  margin-bottom: 0;
}
.about-employee-profile-right-title-text {
  font-size: 2.3vw;
  font-family: 'Muli', Arial;
}
.about-employee-profile-right-award-text {
  font-size: 1.7vw;
  font-family: 'Muli', Arial;
  margin: 0;
  padding: 0;
}
.about-employee-profile-name-text {
  text-align: center;
  font-size: 11vw;
  font-weight: 800;
  font-family: 'Muli', Arial;
  text-transform: uppercase;
  margin-bottom: 0;
  color: white;
}
.about-employee-profile-title-text {
  text-align: center;
  font-size: 4vw;
  font-family: 'Muli', Arial;
  color: white;
  margin-top: 0;
}
.about-employee-profile-award-text {
  margin: 0;
  text-align: center;
  font-size: 4.5vw;
  font-family: 'Muli', Arial;
  color: white;
}
