.footer-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: black;
  margin: 0;
  font-size: 2.2vw;
  margin-bottom: 20px;
  margin-top: 5vh;
  text-align: left;
}
.footer-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: black;
  margin: 0;
  font-size: 1.5vw;
  margin-bottom: 10px;
  margin-top: 3vh;
  text-align: left;
}
.footer-text {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-weight: 300;
  margin: 0;
  font-size: 1.4vw;
  text-align: left;
}
.footer-copyright-text {
  font-family: 'Poppins', sans-serif;
  color: black;
  font-weight: 400;
  margin: 0;
  font-size: 20px;
  text-align: center;
  padding-top: 1vh;
  margin-top: 0;
}
.footer-copyright-link {
  text-decoration: none;
  color: black;
  font-weight: 700;
}
.footer-item-container {
  display: inline-block;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
}
.footer-item-container > a {
  text-decoration: none;
}
.footer-icon-container {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
}
.footer-divider {
  height: 1px;
  background-color: black;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1200px) {
  .footer-title {
    font-size: 3.3vw;
  }
  .footer-subtitle {
    font-size: 2.5vw;
    margin-top: 3vh;
  }
  .footer-text {
    font-size: 2vw;
  }
}
@media screen and (max-width: 700px) {
  .footer-title {
    font-size: 5vw;
  }
  .footer-subtitle {
    font-size: 4.5vw;
    margin-top: 3vh;
  }
  .footer-text {
    font-size: 4vw;
  }
  .footer-item-container {
    width: 70vw;
    display: block;
    text-align: left;
  }
}
