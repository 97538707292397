.navbar {
  max-height: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #a9a9a9;
}
.navbar-item:hover .dropdown-extension {
  display: block;
}
.navbar-item:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
}
.navbar-collection-left {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.navbar-collection-right {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  text-align: right;
}
.navbar-logo-item {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-logo {
  height: 50%;
  position: relative;
}
.navbar-item {
  margin: 20px;
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.navbar-text {
  font-size: 16px;
  letter-spacing: 0.15em;
  font-family: 'Montserrat', sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #a9a9a9,
    #a9a9a9 50%,
    #333333 50%
  );
  background-size: 200% 100%;
  background-position: 100%;
}
.navbar-item:hover .navbar-text {
  transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
}
.headroom {
  z-index: 2 !important;
}
@media screen and (max-width: 1290px) {
  .navbar-text {
    font-size: 12px;
  }
}
@media screen and (max-width: 1050px) {
  .navbar-text {
    font-size: 10px;
  }
}
@media screen and (min-width: 2000px) {
  .navbar {
    max-height: 150px;
    height: 150px;
  }
  .navbar-text {
    font-size: 20px;
  }
  .navbar-item {
    margin: 50px;
  }
}
@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  .navbar {
    max-width: 100vw;
    justify-content: space-around;
  }
  .navbar-text {
    background-image: none;
  }
  .navbar-logo-item {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    top: 0;
  }
  .navbar-collection-left {
    margin-left: 10vw;
    margin-right: 0;
  }
  .navbar-collection-right {
    margin-right: 10vw;
    margin-left: 0;
  }
  .navbar-logo {
    height: 50%;
    margin: 0;
    padding: 0;
  }
}
