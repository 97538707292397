.profiles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
}
.profile-overlay-content {
  position: absolute;
  bottom: 5%;
  width: 100%;
}
.profile-container {
  width: 22vw;
  height: 22vw;
  margin-top: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-image {
  width: 70%;
  height: 70%;
  object-fit: cover;
  border-radius: 50%;
  transition: border-radius 200ms linear, height 200ms linear,
    width 200ms linear, opacity 200ms linear;
}

.profile-overlay {
  position: absolute;
  height: calc(22vw * 0.7);
  width: calc(22vw * 0.7);
  border-radius: 50%;
  transition: border-radius 200ms linear, background-color 200ms linear,
    height 200ms linear;
}
.profile-overlay:hover,
.profile-overlay:active,
.profile-overlay:focus {
  background: rgba(0, 0, 0, 0.2);
  height: 22vw;
  border-radius: 0;
}

.profile-read-more {
  position: absolute;
  margin: 0;
  padding-top: 0.3vw;
  text-align: center;
  width: 100%;
  font-family: 'Montserrat', Arial;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: opacity 200ms linear;
  font-size: 1vw;
  visibility: visible;
  font-weight: 400;
}

.profile-overlay:hover ~ .profile-image {
  border-radius: 0;
  height: 100%;
  width: 70%;
}

.profile-overlay:hover .profile-overlay-name {
  visibility: visible;
  opacity: 1;
}
.profile-overlay:hover .profile-overlay-title {
  visibility: visible;
  opacity: 1;
}
.profile-overlay:hover .profile-overlay-award {
  visibility: visible;
  opacity: 1;
}
.profile-overlay:hover .profile-name {
  opacity: 0;
}
.profile-overlay:hover .profile-read-more {
  opacity: 0;
}
.profile-overlay-name-header {
  margin-left: 3%;
  margin-bottom: 3%;
  margin-right: 3%;

  font-size: 0;
}
.profile-overlay-name {
  background-color: white;
  font-family: 'Montserrat', Arial;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2vw;
  opacity: 0;
  transition: opacity 200ms linear;
  visibility: hidden;
  letter-spacing: 2px;
  text-indent: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding-left: 2px;
  padding-right: 2px;
}
.profile-overlay-title-header {
  margin-bottom: 1px;
  margin-top: 1px;
  font-size: 0;
  margin-left: 3%;
  margin-right: 3%;
}
.profile-overlay-award-header {
  margin-bottom: 1px;
  margin-top: 1px;
  font-size: 0;
  margin-left: 3%;
  margin-right: 3%;
}
.profile-overlay-title {
  background-color: white;
  font-family: 'Montserrat', Arial;
  text-transform: uppercase;
  text-align: left;
  font-weight: 700;
  font-size: 0.8vw;
  opacity: 0;
  transition: opacity 200ms linear;
  visibility: hidden;
  letter-spacing: 0.5px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding-left: 2px;
  padding-right: 2px;
}
.profile-divider {
  height: 5px;
}
.profile-overlay-award {
  background-color: white;
  font-family: 'Montserrat', Arial;
  text-transform: uppercase;
  text-align: left;
  font-weight: 700;
  font-size: 0.6vw;
  opacity: 0;
  transition: opacity 200ms linear;
  visibility: hidden;
  letter-spacing: 0.5px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding-left: 2px;
  padding-right: 2px;
}
.profile-name {
  position: absolute;
  margin: 0;
  padding-bottom: 10vw;
  padding-top: 1vw;
  text-align: center;
  width: 100%;
  font-family: 'Montserrat', Arial;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: opacity 200ms linear;
  font-size: 1.3vw;
  visibility: visible;
}

.about-header {
  font-size: 4vw;
  text-align: center;
  font-family: 'Montserrat', Arial;
  letter-spacing: 0.7vw;
  font-weight: 700;
  color: black;
  margin-top: 10vh;
}
.decorated {
  overflow: hidden;
  text-align: center;
}
.decorated > span {
  position: relative;
  display: inline-block;
  font-size: 3vw;
  font-family: 'Montserrat', Arial;
  color: #212121;
  letter-spacing: 3px;
  font-weight: 300;
}
.decorated > span:before,
.decorated > span:after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 2px solid;
  border-color: #a9a9a9;
  width: 5vw; /* half of limiter */
  margin: 0 20px;
}
.decorated > span:before {
  right: 100%;
}
.decorated > span:after {
  left: 100%;
}
.megastore {
  margin-top: 5vh;
}
.premium {
  margin-top: 5vh;
}
.about-divider {
  width: 50vw;
  border-style: solid;
  border-width: 0 2px 0 0;
  height: 15vw;
  margin-top: 5vh;
}
.about-quote {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2.5vw;
  letter-spacing: 0.4vw;
  font-style: italic;
  text-align: center;
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
}
.about-info-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5vw;
  text-align: left;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vw;
}
.about-greeting-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8vw;
  text-align: center;
  width: 45vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vw;
}

@media only screen and (max-width: 980px) {
  .profile-container {
    width: 45vw;
    height: 45vw;
  }

  .decorated > span {
    font-size: 20px;
  }

  .profile-overlay {
    height: calc(45vw * 0.7);
    width: calc(45vw * 0.7);
  }
  .profile-overlay:hover,
  .profile-overlay:active,
  .profile-overlay:focus {
    height: 45vw;
  }
  .profile-name {
    margin-top: 3vw;
    font-size: 2.5vw;
  }

  .profile-read-more {
    font-size: 1.8vw;
  }

  .profile-overlay-name {
    font-size: 2.7vw;
  }
  .profile-overlay-title {
    font-size: 2vw;
  }
  .profile-overlay-award {
    font-size: 1.5vw;
  }
  .about-quote {
    width: 90vw;
    font-size: 4.5vw;
  }
  .about-info-text {
    font-size: 3.5vw;
    width: 80vw;
  }
  .about-greeting-text {
    font-size: 6vw;
    width: 80vw;
  }
}
@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  .profile-overlay {
    position: absolute;
    margin-top: -11vw;
    visibility: visible;
    height: 22vw;
    width: calc(22vw * 0.7);
    transition: border-radius 200ms linear, background-color 200ms linear,
      height 200ms linear;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }
  .profile-image {
    width: 70%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
    transition: border-radius 200ms linear, height 200ms linear,
      width 200ms linear, opacity 200ms linear;
  }
  .profile-name {
    margin-top: 10px;
  }
  .profile-container {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 0\0) and (min-resolution: +72dpi) and (max-width: 980px) {
  .profile-overlay {
    height: 45vw;
    width: calc(45vw * 0.7);
    margin-top: -22.5vw;
  }
  .profile-name {
    margin-top: 2.5vw;
  }
}

@supports (-ms-ime-align: auto) {
  .profile-name {
    margin-top: 0.5vw;
  }
  @media only screen and (max-width: 970px) {
    .profile-name {
      margin-top: 2vw;
    }
  }
}
