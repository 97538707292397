.media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  left: 0px;
  position: relative;
  z-index: -2;
  overflow: hidden;
}
.about-divider {
  width: 50vw;
  border-style: solid;
  border-color: black;
  border-width: 0 2px 0 0;
  height: 15vw;
  margin-top: 5vh;
}
.collection-info-text {
  font-family: 'Montserrat';
  font-size: 16px;
}
.media-container {
  height: 90vh;
  overflow: hidden;
}
.video-overlay {
  position: absolute;
  height: inherit;
  width: 100%;
  top: 100px;
  transition: background 0.5s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}
.booking-button-container {
  padding: 30px 60px;
  font-size: 40px;
  textdecoration: none;
  color: white;
  border-style: solid;
  border-color: white;
  font-weight: 700;
  letter-spacing: 5px;
  font-family: 'Montserrat', Arial;
  background: rgba(15, 15, 15, 0.3);
  transition: 0.7s;
  text-decoration: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 50vh;
  z-index: 0;
  transition: opacity 0.5s linear, background 0.5s ease;
  cursor: pointer;
}
.booking-button-container:hover {
  background: rgba(255, 255, 255, 0.2);
}
.instagram-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.instagram-image-slider {
  width: 100vw;
  height: 100vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}
.instagram-container {
  background-color: #ffffff;
}
.instagram-feed {
  display: grid;

  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 25vw);
  grid-gap: 0;
  padding-bottom: 10vw;
}
.instagram-profile-container {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-top: 2vw;
  margin-bottom: 2vw;
}
.instagram-profile-picture {
  border-radius: 50%;
  height: 7vw;
  margin-left: 10vw;
}
.instagram-profile-username {
  font-family: 'Montserrat';
  text-transform: uppercase;
  margin-left: 2vw;
  font-size: 2.3vw;
}
.instagram-profile-link {
  text-decoration: none;
  color: black;
}

.readmore-container {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #ffffff;
  height: 45vw;
}
.readmore-header-container {
  width: 40%;
  position: relative;
  display: block;
  left: 23vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vw;
  padding: 0;
}
.readmore-image {
  display: block;
  height: 55vw;
  position: absolute;
  margin-left: 10vw;
}
.readmore-header-text {
  font-size: 1.5vw;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 2vw;
}
.readmore-awards-text {
  font-size: 1.1vw;
  font-family: 'poppins', sans-serif;
  font-weight: 300;
  font-style: italic;
  letter-spacing: 2px;
  margin-bottom: 3.5vw;
  color: black;
}
.readmore-awards-header-text {
  background-color: black;
  color: white;
  margin-bottom: 20px;
  padding: 5px;
}
.readmore-link-text {
  text-decoration: none;
  cursor: pointer;
  font-size: 1.7vw;
  letter-spacing: 0.15em;
  font-weight: 400;
  font-family: 'Montserrat';
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #a9a9a9,
    #a9a9a9 50%,
    #333333 50%
  );
  background-size: 200% 100%;
  background-position: 100%;
}
.readmore-link-text:hover {
  transition: all 0.7s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
}
.collection-container {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f2f2f2;
  height: 60vw;
}
.collection-first-image {
  display: block;
  height: 33vw;
  position: absolute;
  margin-left: 20vw;
  margin-top: 15.2vw;
}
.collection-second-image {
  display: block;
  height: 40vw;
  position: absolute;
  margin-left: 36vw;
  margin-top: 12vw;
  z-index: 1;
}
.collection-third-image {
  display: block;
  height: 25vw;
  position: absolute;
  margin-left: 62vw;
  margin-top: 25vw;
}
.collection-header-container {
  text-align: left;
  width: 27%;
  font-size: 1.2vw;
  position: absolute;
  display: block;
  left: 67vw;
  margin-top: 10vw;
  padding: 0;
  color: black;
  font-family: 'Poppins', sans-serif;
  z-index: 1;
}
.collection-header-text {
  font-size: 2.4vw;
  font-weight: 900;
  margin-bottom: 0;
  font-family: 'Montserrat';
}
.collection-header-container > p {
  font-family: 'Montserrat';
  font-size: 1.3vw;
  font-weight: 400;
}
.collection-link-container {
  width: 30%;
  position: absolute;
  display: block;
  left: 12vw;
  margin-top: 49.5vw;
  padding: 0;
  text-align: center;
}
.collection-link-text {
  text-decoration: none;
  cursor: pointer;
  font-size: 1.2vw;
  font-weight: 100;
  font-family: 'Montserrat';
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    #a9a9a9,
    #a9a9a9 50%,
    #333333 50%
  );
  background-size: 200% 100%;
  background-position: 100%;
}
.collection-link-text:hover {
  transition: all 0.7s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
}
.collection-image {
  display: block;
  width: 100%;
  height: 50vh;
  object-fit: cover;
  opacity: 0.6;
  position: absolute;
}
.collection-link-text-mobile {
  font-size: 5vw;
  color: black;
  text-decoration: none;
}
.readmore-link-text-mobile {
  font-size: 1.4rem;
  margin-top: 70px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: black;
  color: white;
  font-family: 'Montserrat';
  text-decoration: none;
}

@media screen and (max-width: 980px) {
  .booking-button-container {
    font-size: 25px;
    padding: 20px 20px;
    width: 55vw;
    text-align: center;
    transform: translate(-50%, -50%);
  }
  .instagram-profile-container {
    justify-content: initial;
    align-items: center;
    padding-top: 3vw;
    margin-bottom: 3vw;
  }
  .instagram-profile-picture {
    height: 10vw;
    margin-left: 5vw;
  }
  .instagram-profile-username {
    margin-left: 2vw;
    font-size: 20px;
  }
  .instagram-container {
    padding-bottom: 10vw;
  }
  .collection-container {
    padding-top: 0;
    background-color: white;
    text-align: center;
    height: 50vh;
    padding-bottom: 0;
  }
  .collection-header-container {
    position: absolute;
    height: 40vh;
    display: block;
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;
    left: 0;
    padding: 0;
    margin-top: 14vw;
  }
  .collection-header-text {
    font-size: 6em;
    font-family: 'Montserrat', sans-serif;
    background-color: black;
    font-style: italic;
    color: white;
    padding: 10px;
    text-transform: uppercase;
    margin-top: 0;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }

  .readmore-container {
    padding-top: 100px;
    background-color: #ffffff;
    height: auto;
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .readmore-header-container {
    position: initial;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    padding: 0;
  }

  .readmore-image {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    opacity: 0.5;
    position: absolute;
    margin: 0;
  }
  .readmore-header-text {
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 2px;
    margin-top: 0;
    margin-bottom: 50px;
    color: black;
  }

  .readmore-awards-text {
    font-size: 1rem;
    font-weight: 700;
  }
}

@media screen and (max-width: 980px) and (orientation: landscape) {
  .media {
    object-position: 50% 50%;
  }
  .readmore-header-container {
    height: 50vh;
  }
  .collection-header-container {
    height: 60vh;
  }
  .instagram-image-slider {
    width: 100vh;
    height: 100vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 2000px) {
  .video-overlay {
    top: 150px;
  }
}

@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  .instagram-feed {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .feed-cell {
    width: 25vw;
    height: 25vw;
    -ms-grid-column: 3;
  }
  .readmore-link-text {
    background-image: none;
    color: black;
  }
  .collection-link-text {
    background-image: none;
    color: black;
  }
}

@supports (object-fit: cover) and (-ms-ime-align: auto) {
  .media {
    height: auto;
  }
  .instagram-feed {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .feed-cell {
    width: 25vw;
    height: 25vw;
    -ms-grid-column: 3;
  }
}

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
